import React, { useState } from "react";
import "./map.scss";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import LaunchBanner from "../LaunchBanner/LaunchBanner";

const Map = () => {
  const [showBanner, setShowBanner] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(null);


  const handleClick = (geo) => {
    setShowBanner(true);
    setSelectedCountry(geo.properties.name); // You can use the country name or any identifier
  };

  const handleCloseBanner = () => {
    setShowBanner(false);
    setSelectedCountry(null);
  };

  return (
    <div className="map">
      <div className="title__container">
        <span className="title">Embark on Culinary Journey</span>
        <span className="sub__title">
          Discover Regional Recipes - Select region within country
        </span>
      </div>
      <div className="map__container">
        <ComposableMap
        className="map__svg"
          style={{
            width: "100%",
            height: "120vh",
            pressed: {
              outline: "none",
              
            },
          }}
        >
          <Geographies
            style={{
              default: {
                fill: "#D6D6DA",
              },
              hover: {
                fill: "#E85B6A",
                transition: "fill 0.3s ease-in-out",
              },
              pressed: {
                outline: "none",
              },
            }}
            geography="/features.json"
          >
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  style={{
                    default: {
                      fill: "#D6D6DA",
                    },
                    hover: {
                      fill: "#E85B6A",
                      transition: "fill 0.3s ease-in-out",
                    },
                    pressed: {
                      fill: "#E85B6A",
                      outline: "none",
                    },
                  }}
                  fill="#D9D9D9"
                  stroke="white"
                  strokeWidth="0.3"
                  onClick={() => handleClick(geo)}
                  key={geo.rsmKey}
                  geography={geo}
                />
              ))
            }
          </Geographies>
        </ComposableMap>
      </div>

        {showBanner && (
          <LaunchBanner
            onClose={handleCloseBanner}
          />
        )}
    </div>
  );
};

export default Map;
