import React from 'react'
import Header from '../components/Header/Header'
import Map from '../components/Map/Map'
import Footer from '../components/Footer/Footer'
import Metadata from '../components/Metadata/Metadata'

const HomePage = () => {
  return (
    <div >
      <Metadata Title="Platters That Matter"/>
        <Header/>
          <Map/>
        <Footer/>
    </div>
  )
}

export default HomePage
