import React, { useState } from "react";
import "./launch-banner.scss";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { motion } from "framer-motion";
import Metadata from "../Metadata/Metadata";

const LaunchBanner = ({ onClose }) => {
  const emailCollectionRef = collection(db, "ptm-landing");

  const [newEmail, setNewEmail] = useState("");

  const sendEmail = async (e) => {
    e.preventDefault();
    if (newEmail.trim() === "" || !newEmail.includes("@")) {
      const toastId = toast.error("Please enter a valid email address!", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: false,
        progress: undefined,
        style: {
          fontFamily: "Tate",
        },
      });

      return false;
    } else if (newEmail.trim() !== "" && newEmail.includes("@")) {
      const toastId = toast.success("Thank you for subscribing!", {
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: false,
        progress: undefined,
        style: {
          fontFamily: "Tate",
        },
      });

      await addDoc(emailCollectionRef, {
        email: newEmail,
      });

      setNewEmail("  ");
      // delay the closing of the banner by 3 seconds
      setTimeout(() => {
        onClose();
      }, 2000);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
      className="launch__banner"
    >
      <Metadata Title="Platters That Matter - Launching Soon" />
      <div className="close__btn" onClick={onClose}>
        <div className="line"></div>
        <div className="line"></div>
      </div>

      <div className="launching__soon">
        <span>Launching soon!</span>
      </div>

      <div className="contact__us">
        <span>Contact us to get notified about the launch!</span>

        {/* contact Us form */}
        <form className="contact__form" action="" onSubmit={sendEmail}>
            <input
              type="email"
              placeholder="Enter your email address"
              onChange={(e) => {
                setNewEmail(e.target.value);
                console.log(newEmail);
              }}
            />
          <button type="submit">Submit</button>
          <ToastContainer />
        </form>
      </div>
    </motion.div>
  );
};

export default LaunchBanner;
