import React, { useEffect, useState } from "react";
import { db } from "../config";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { CSVLink } from "react-csv";
import Metadata from "../components/Metadata/Metadata";
import { useHistory } from "react-router-dom";
import { useAuth } from "../Context/AuthContext";

const AdminHome = ({ Name }) => {
  // get data from firebase firestore
  // display data in table
  // export data to csv

  const [dataFromFirestore, setDataFromFirestore] = useState([]);
  const headers = [{ label: "Email", key: "email" }];

  const { dispatch } = useAuth();
  const history = useHistory();

  const handleLogout = () => {

    //handle when back button is pressed
    window.onpopstate = e => {
      e.preventDefault();
      history.push("/admin/login");
    } 

    dispatch({ type: "LOGOUT" });
    history.push("/admin/login");
  };

  useEffect(() => {
    fetchDataFromFirestore();
  }, []);

  const fetchDataFromFirestore = async () => {
    const dataCollectionRef = collection(db, "ptm-landing");

    try {
      const querySnapshot = await getDocs(dataCollectionRef);
      const data = [];

      querySnapshot.forEach((doc) => {
        data.push({ id: doc.id, ...doc.data() });
      });

      setDataFromFirestore(data);
    } catch (error) {
      console.error("Error fetching data from Firestore: ", error);
    }
  };

  return (
    <div className="container d-flex flex-column vw-100 my-4">
      <Metadata Title="Admin Dashboard - PTM" />
      <div className="d-flex justify-content-around w-100 vw-100 ">
        <h3 className="admin__name">Welcome {Name}</h3>

        <div className="btn__export__csv">
          <button className="btn" style={{ backgroundColor: "#E85B6A" }}>
            <CSVLink
              style={{ color: "white" }}
              data={dataFromFirestore}
              headers={headers}
              filename={"exported-data.csv"}
            >
              Export to CSV
            </CSVLink>
          </button>
          <button
            onClick={handleLogout}
            className="btn"
            style={{
              backgroundColor: "#E85B6A",
              marginLeft: "10px",
              color: "white",
            }}
          >
            Signout
          </button>
        </div>
      </div>

      <div className="records mt-5 w-100 ">
        <table className="table">
          <thead>
            <tr className="">
              <th className="col-2 ">#</th>
              <th className="col-10">Email</th>
            </tr>
          </thead>
          <tbody>
            {dataFromFirestore.map((item, index) => (
              <tr key={item.id}>
                <th className="row">{index + 1}</th>
                <td>{item.email}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminHome;
