import React from "react";
import "./header.scss";
import Logo from "../../assets/img/PTM-Logo.png";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Header = () => {
  return (
    <div className="container-fluid text-light py-3">
      <div className="row">
        <div className="col-12 col-md-4 text-center text-md-left">
          {/* Content for the left section */}
        </div>
        <div className="col-12 col-md-4 text-center">
          {/* Content for the center section */}
          <Link  to="/">
            <img src={Logo} alt="" className="img-fluid" />
          </Link>
        </div>
        <div className="col-12 col-md-4 text-center text-md-right">
          {/* Content for the right section */}
        </div>
      </div>
    </div>
  );
};

export default Header;
