import React from "react";

const Footer = () => {
  return (
    <div className="footer" >
      <hr className="mb-3" />
      <div className="container-fluid mb-3 px-5">
        <div className="row">
          <div className="col-12 col-md-4 text-center text-md-left">
            &#169; <span>2022 Platters That Matter</span>
          </div>
          <div className="col-12 col-md-4 text-center">
            <span>Privacy</span>
          </div>
          <div className="col-12 col-md-4 text-center text-md-right">
            Terms & Conditions
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
