import React, { useState } from "react";
import "./admin.css";
import Metadata from "../components/Metadata/Metadata";
import { auth } from "../config";
import { signInWithEmailAndPassword } from "firebase/auth";
import {  useHistory } from "react-router-dom"
import { Link } from "react-router-dom/cjs/react-router-dom.min";



const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const history = useHistory()


  const handleLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        history.push("/admin/dash")
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Metadata Title="Admin Login - PTM" />
      <div className="wrapper fadeInDown">
        <div id="formContent">
          <Link to="/" className="fadeIn first">
            <img className="mb-3" src={"/PTM-Logo.png"} id="icon" alt="User Icon" />
          </Link>

          <form onSubmit={handleLogin}>
            <input
              type="email"
              id="login"
              className="fadeIn second"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              id="password"
              className="fadeIn third"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <input type="submit" className="fadeIn fourth" value="Log In" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
