import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
// import .env file


const firebaseConfig = {
  apiKey: "AIzaSyCqHbYgvv1hevBKc1ZSvcv3sWkMFcNtRLI",
  authDomain: "ptm-landing.firebaseapp.com",
  projectId: "ptm-landing",
  storageBucket: "ptm-landing.appspot.com",
  messagingSenderId: "833668906321",
  appId: "1:833668906321:web:2d9cb341815bbcaf0ce770",
  measurementId: "G-8YQF6XEELD"
};
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
export const auth = getAuth(app);
