import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Metadata from "../Metadata/Metadata";

const NotFound = () => {
  return (
    <div className="container d-flex flex-column justify-content-center align-items-center ">
      <Metadata Title="404 Not Found" />
      <br />
      <br />
      <br />
      <br />
      <h1>Oops</h1>
      <h2>404 Not Found</h2>
      <div className="error-details">
        Sorry, an error has occurred, Requested page not found!
      </div>
      <div className="error-actions">
        <Link to="/" className="btn btn-lg">
          Go to Home Page
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
