import { Route, Switch } from "react-router-dom";
import { AuthProvider } from "./Context/AuthContext";
import HomePage from "./pages/HomePage";
import AdminLogin from "./pages/AdminLogin";
import AdminHome from "./pages/AdminHome";
import PrivateRoute from "./utils/PrivateRoute";
import NotFound from "./components/NotFound/NotFound";

function App() {
  return (
    <>
    <AuthProvider>
      <Switch>
        <PrivateRoute exact path="/admin/dash" component={AdminHome} />
        <Route exact path="/admin/login" component={AdminLogin} />
        <Route exact path="/" component={HomePage} />
        <Route component={NotFound} />
      </Switch>
    </AuthProvider>
   
    </>
  );
}

export default App;
